const Maintenance = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            lineHeight: "20px",
          }}
        >
          Currently down for maintenance. Please contact us at{" "}
          <a href="mailto:support@reiterate.com">support@reiterate.com</a> for more information.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;
