import React, { SyntheticEvent, useEffect, useState } from "react";

import SidebarWindow from "../common/SidebarWindow";
import { Box } from "@mui/system";
import { fetchAgent, fetchAgents } from "../common/utils";
import { Button, Grid, IconButton, Input, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Agent } from "../common/agentTypes";
import AgentSetupStepper from "../common/AgentSetupStepper";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import AgentSetupStepperWindow from "../common/AgentSetupStepperWindow";
import { AgentConfig, AgentConfigDTO, type StringParameter, type TOTPParameter, WebAgent } from "../../../spec/agent";
import update from 'immutability-helper';
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";

interface AgentSettingsViewProps {}


const AgentSettingsView = ({}: AgentSettingsViewProps): React.JSX.Element => {
  const [agent, setAgent] = React.useState<WebAgentDto | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  function setUrl(url: string) {
    setAgent(update(agent, {config: {url: {$set: url}}}));
  }



  const params = agent?.config?.parameters;

  // Agents API
  const boApiClient = useBoApiClient();

  const { agentUuid } = useParams();
  const navigate = useNavigate();


  const handleStringParamChange = (fieldName, newValue) => {
    setAgent(update(agent, {config: {parameters: {[fieldName]: {$set: {type: "string", value: newValue}}}}}));
  };

  const getUsername = () => {
    // @ts-ignore
    return agent?.config?.parameters?.username?.value;
  }

  const getPassword = () => {
    // @ts-ignore
    return agent?.config?.parameters?.password?.value;
  }
  const getOtp = () => {
    // @ts-ignore
    return agent?.config?.parameters?.otp?.value;
  }

  const handleSubmit = () => {
    // Collect form data to send or use as needed

    const request: AgentConfigDTO = {
      url: agent?.config?.url,
      prompt: agent?.config?.prompt,
      options: agent?.config?.options,
      // @ts-ignore
      parameters: agent?.config?.parameters
    }

    boApiClient.proxyWebAgentAgents.updateAgent(agentUuid, request).then( () => {
      navigate(`/app/agents/${agentUuid}/chat`);
      }
    )
  };


  useEffect(() => {
    boApiClient.proxyWebAgentAgents.getAgent(agentUuid).then((a) => {
      console.log(a);
      setAgent(a);
    });
  }, [])

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }

  useEffect(() => {
    console.log(agent?.config.parameters);
    console.log(agent);
  }, [agent]);



  return (
  <SidebarWindow>
    <Box sx={{width:"100%", justifyContent:"center", display: "flex", overflowY: "auto"}}>
      <AgentSetupStepperWindow stepperOptions={{activeStep:0, agentUuid: agentUuid, handleNextButtonSave: handleSubmit}}>
      <Box width={"100%"} sx={{display: "flex", justifyContent:"center"}}>
          <Box component="form" onSubmit={handleSubmit} sx={{ width: "60%", minWidth: 300, mx: 'auto', mt: 4, p: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>Browser agent</Typography>

            <Typography>Starting URL</Typography>
            <TextField
              fullWidth
              margin="normal"
              required={true}
              value={agent?.config?.url}
              placeholder={"https://google.com"}
              onChange={(e) => setUrl(e.target.value)}
              autoFocus={true}
            />

            <Typography variant="h6" gutterBottom sx={{mt: 2}}>Parameters</Typography>
            <Typography variant="body2" color={"#565656"} gutterBottom sx={{mt: 1}}>These are secrets that will not be provided to external data processors.</Typography>
            <Typography variant="body2" color={"#565656"} gutterBottom sx={{}}>To use the post-processing use them within the prompting like this:</Typography>
            <Typography variant="body2" color={"#565656"} gutterBottom sx={{}}>&gt; Log in using $username and $password</Typography>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              sx={{
                mt: 2,
                minHeight: "unset",
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  alignItems: "center !important",
                  maxHeight: "32px",
                },
                ".MuiTab-iconWrapper": {
                  marginBottom: "0 !important",
                  marginRight: "4px",
                },
                ".MuiTab-root": {
                  borderRadius: "24px !important",
                },
                ".Mui-selected": {
                  color: `#142E90 !important`,
                  backgroundColor: "#EEF1FB",

                  minHeight: "unset",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Tab
                label="Login details"
                sx={{
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  height: "32px",
                }}
              />
            </Tabs>
            {tabIndex === 0 && <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField   InputLabelProps={{shrink: true}} label={"Username"} value={getUsername()} onChange={(e) => {
                handleStringParamChange("username", e.target.value);
              }} sx={{ maxWidth: "400px", mt: 4 }}></TextField>
              <TextField   InputLabelProps={{shrink: true}} label={"Password"} value={getPassword()} type={"password"} onChange={(e) => {
                handleStringParamChange("password", e.target.value);
              }} sx={{ maxWidth: "400px", mt: 1 }}></TextField>
            </Box>}
            {tabIndex === 1 && <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField label={"OTP Secret"} value={getOtp()} onChange={(e) => {
                handleStringParamChange("otp_secret", e.target.value);
              }} sx={{ maxWidth: "400px", mt: 4 }}></TextField>
            </Box>}
          </Box>

      </Box>
      </AgentSetupStepperWindow>
    </Box>
  </SidebarWindow>)
}

export default AgentSettingsView;