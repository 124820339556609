/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkflowRunDto } from '../models/WorkflowRunDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowRunsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param workflowId
   * @returns WorkflowRunDto Success
   * @throws ApiError
   */
  public create(
    workflowId: string,
  ): CancelablePromise<WorkflowRunDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows/{workflowId}/runs',
      path: {
        'workflowId': workflowId,
      },
    });
  }

  /**
   * @param workflowId
   * @param skip
   * @param limit
   * @returns WorkflowRunDto Success
   * @throws ApiError
   */
  public getAll(
    workflowId?: string,
    skip?: number,
    limit: number = 100,
  ): CancelablePromise<Array<WorkflowRunDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/runs',
      query: {
        'workflowId': workflowId,
        'skip': skip,
        'limit': limit,
      },
    });
  }

  /**
   * @param id
   * @returns WorkflowRunDto Success
   * @throws ApiError
   */
  public get(
    id: string,
  ): CancelablePromise<WorkflowRunDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/runs/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param workflowId
   * @param id
   * @returns WorkflowRunDto Success
   * @throws ApiError
   */
  public get1(
    workflowId: string,
    id: string,
  ): CancelablePromise<WorkflowRunDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/{workflowId}/runs/{id}',
      path: {
        'workflowId': workflowId,
        'id': id,
      },
    });
  }

}
