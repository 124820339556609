/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentConfigDTO } from '../models/AgentConfigDTO';
import type { AgentStep } from '../models/AgentStep';
import type { Download } from '../models/Download';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SessionsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Finish
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public finishAgentSessions(
    sessionId: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/v1/session/{session_id}',
      path: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * GetDownload
   * @param sessionId
   * @returns Download Document created, URL follows
   * @throws ApiError
   */
  public downloadAgentSession(
    sessionId: string,
  ): CancelablePromise<Download> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/session/{session_id}/download',
      path: {
        'session_id': sessionId,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * List
   * @returns number Request fulfilled, document follows
   * @throws ApiError
   */
  public listAgentSessions(): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/session',
    });
  }

  /**
   * Start
   * @param requestBody
   * @returns string Document created, URL follows
   * @throws ApiError
   */
  public startAgentSession(
    requestBody: AgentConfigDTO,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/session',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * SetPrompt
   * @param sessionId
   * @param requestBody
   * @returns string Request fulfilled, document follows
   * @throws ApiError
   */
  public setAgentPrompt(
    sessionId: string,
    requestBody: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/v1/session/{session_id}/prompt',
      path: {
        'session_id': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * Step
   * @param sessionId
   * @param accept
   * @returns any Document created, URL follows
   * @throws ApiError
   */
  public stepAgentSession(
    sessionId: string,
    accept: boolean,
  ): CancelablePromise<(null | AgentStep)> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/session/{session_id}/step',
      path: {
        'session_id': sessionId,
      },
      query: {
        'accept': accept,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

}
