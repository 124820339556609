import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, capitalize, MenuList, Tooltip } from "@mui/material";
import UIContext from "../../context/UIContext";
import {
  AgentListItem,
  AppNavLink,
  Avatar,
  ExternalLink,
  HelpListIcon,
  IntegrationsListItem,
  Logout,
  LogoutListItem,
  LookerDashboardItem,
  LookerExploreItem,
  MatchingListItem,
  ProjectsListItem,
  SettingsListItem,
  SettlementsListItem,
  UploadListItem,
  WorkflowsListItem,
} from "./SidebarElements";
import MixpanelContext from "../../context/MixpanelContext";
import { AUTH0_ORG_CLAIM_URL } from "../../utils/auth/config";
import { useFlags } from "launchdarkly-react-client-sdk";

const AppSidebar: React.FC = () => {
  const auth0 = useAuth0();
  const organization = auth0.user[AUTH0_ORG_CLAIM_URL] || "";
  const {
    logout,
    user: { nickname },
  } = auth0;

  const { toolVisibilitySettings } = useContext(UIContext);
  const { mixpanel } = useContext(MixpanelContext);
  const { gen1460Workflows, gen3865WebAgent  } = useFlags();

  return (
    <Box sx={{ minWidth: "64px", backgroundColor: "primary.dark" }}>
      <Box
        sx={{
          height: "65px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 4,
        }}
      >
        <Tooltip title={capitalize(organization)}>
          <Avatar>{nickname.charAt(0).toUpperCase()}</Avatar>
        </Tooltip>
      </Box>
      <Box
        sx={{
          height: "calc(100% - 97px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MenuList sx={{ color: "onDark" }}>
          <Tooltip title="Upload" placement="right">
            <AppNavLink
              onClick={() => {
                mixpanel.track("Navigation", {
                  "Navigation Item": "/app/upload",
                  "Navigation Type": "Sidebar",
                  "Navigation Action": "Click",
                  "Navigation Route": "Upload",
                });
              }}
              to={"/app/upload"}
              children={({ isActive }) => (
                <UploadListItem $isActive={isActive} data-action="Navigate to /app/upload" />
              )}
            />
          </Tooltip>
          {toolVisibilitySettings?.matching_tool && (
            <Tooltip title="Payments reconciliation" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/matching",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Payment reconciliation",
                  });
                }}
                to={"/app/matching"}
                children={({ isActive }) => (
                  <MatchingListItem $isActive={isActive} data-action="Navigate to /app/matching" />
                )}
              />
            </Tooltip>
          )}

          {gen1460Workflows && (
            <Tooltip title="Workflows" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/tasks",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Tasks",
                  });
                }}
                to={"/app/tasks"}
                children={({ isActive }) => (
                  <WorkflowsListItem $isActive={isActive} data-action="Navigate to /app/tasks" />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.looker_explore && (
            <Tooltip title="Explore" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/explore",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Explore",
                  });
                }}
                to={"/app/explore"}
                children={({ isActive }) => (
                  <LookerExploreItem $isActive={isActive} data-action="Navigate to /app/explore" />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.looker_dashboard && (
            <Tooltip title="Dashboard" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/dashboard",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Dashboard",
                  });
                }}
                to={"/app/dashboard"}
                children={({ isActive }) => (
                  <LookerDashboardItem
                    $isActive={isActive}
                    data-action="Navigate to /app/dashboard"
                  />
                )}
              />
            </Tooltip>
          )}
          {toolVisibilitySettings?.fee_analysis && (
            <Tooltip title="Fee estimation" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/projects",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Fee estimation",
                  });
                }}
                to={"/app/projects"}
                children={({ isActive }) => (
                  <ProjectsListItem $isActive={isActive} data-action="Navigate to /app/projects" />
                )}
              />
            </Tooltip>
          )}

          {toolVisibilitySettings?.settlement_tool && (
            <Tooltip title="Settlements" placement="right">
              <AppNavLink
                onClick={() => {
                  mixpanel.track("Navigation", {
                    "Navigation Item": "/app/settlement",
                    "Navigation Type": "Sidebar",
                    "Navigation Action": "Click",
                    "Navigation Route": "Settlements",
                  });
                }}
                to={"/app/settlement"}
                children={({ isActive }) => (
                  <SettlementsListItem
                    $isActive={isActive}
                    data-action="Navigate to /app/settlement"
                  />
                )}
              />
            </Tooltip>
          )}
          {gen3865WebAgent && <Tooltip title="Agent" placement="right">
            <AppNavLink
              onClick={() => {}}
              to={"/app/agents/"}
              children={({ isActive }) => (
                <AgentListItem $isActive={isActive} data-action="Navigate to /app/agents" />
              )}
            />
          </Tooltip>}
        </MenuList>

        <MenuList sx={{ color: "onDark", p: 0 }}>

        </MenuList>
        <MenuList sx={{ color: "onDark", p: 0 }}>
          <Tooltip title="Settings" placement="right">
            <AppNavLink
              onClick={() => {
                mixpanel.track("Navigation", {
                  "Navigation Item": "/app/settings",
                  "Navigation Type": "Sidebar",
                  "Navigation Action": "Click",
                  "Navigation Route": "Settings",
                });
              }}
              to={"/app/settings"}
              children={({ isActive }) => (
                <SettingsListItem $isActive={isActive} data-action="Navigate to /app/settings" />
              )}
            />
          </Tooltip>
          <Tooltip title="Integrations" placement="right">
            <AppNavLink
              onClick={() => {
                mixpanel.track("Navigation", {
                  "Navigation Item": "/app/integrations",
                  "Navigation Type": "Sidebar",
                  "Navigation Action": "Click",
                  "Navigation Route": "Integrations",
                });
              }}
              to={"/app/integrations"}
              children={({ isActive }) => (
                <IntegrationsListItem
                  $isActive={isActive}
                  data-action="Navigate to /app/integrations"
                />
              )}
            />
          </Tooltip>
          <Tooltip title="Knowledge base" placement="right">
            <ExternalLink
              onClick={() => {
                mixpanel.track("Navigation", {
                  "Navigation Item": "knowledge-base",
                  "Navigation Type": "Sidebar",
                  "Navigation Action": "Click",
                  "Navigation Route": "Knowledge base",
                });
              }}
              href="http://knowledge-base.reiterate.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <HelpListIcon data-action="Help" />
            </ExternalLink>
          </Tooltip>

          <Tooltip title="Log out" placement="right">
            <Logout
              onClick={() => {
                mixpanel.track("Logout", {});
                mixpanel.reset();

                logout({ returnTo: window.location.origin + "/login" });
              }}
            >
              <LogoutListItem data-action="Navigate to /logout" />
            </Logout>
          </Tooltip>
        </MenuList>
      </Box>
    </Box>
  );
};

export default AppSidebar;
