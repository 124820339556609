import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { boApiClient } from "../core/api";
import { accessTokenRequest } from "../core/auth";
import { ApiClientBO } from "../spec/bo";

export function useBoApiClient(): ApiClientBO {
  const [client, setClient] = useState<ApiClientBO>(boApiClient);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const createClient = async () => {
      const token = await getAccessTokenSilently(accessTokenRequest);
      boApiClient.request.config.TOKEN = token;
      setClient(boApiClient);
    };

    if (isAuthenticated) {
      void createClient();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return client;
}
