import { Box, Button, Typography } from "@mui/material";
import React from "react";

export function AgentsEmptyState(props: { onClick: () => void }) {
  return <Box sx={{ display: "flex", borderBottom: "0.5px solid #dcdcdc", width: "100%" }}>
    <Box sx={{ py: 2, display: "flex", alignItems: "center", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Typography variant="body2">No agents, yet</Typography>
        <Typography variant="body2" color="rgba(0, 0, 0, 0.60)">
          Agents will be listed here once they are added.
        </Typography>
        <Button variant="contained" color="primary" onClick={props.onClick} sx={{ mt: 2 }}>
          Add Agent
        </Button>
      </Box>
    </Box>
  </Box>;
}