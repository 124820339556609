import { Avatar as MuiAvatar, ListItemIcon as MuiListItemIcon } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink as RouterLink } from "react-router-dom";
import styled from "styled-components";
import lookerDashboardIcon from "../../assets/icons/lookerDashboardIcon.svg";
import lookerDashboardIcon06opacity from "../../assets/icons/lookerDashboardIcon06opacity.svg";
import lookerExploreIcon from "../../assets/icons/lookerExploreIcon.svg";
import lookerExploreIcon06opacity from "../../assets/icons/lookerExploreIcon06opacity.svg";
import matchingIcon from "../../assets/icons/matchingIcon.svg";
import matchingIcon06opacity from "../../assets/icons/matchingIcon06opacity.svg";
import projectsIcon from "../../assets/icons/projectsIcon.svg";
import projectsIcon06opacity from "../../assets/icons/projectsIcon06opacity.svg";
import settlementIcon from "../../assets/icons/settlementIcon.svg";
import settlementIcon06opacity from "../../assets/icons/settlementIcon06opacity.svg";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import uploadIcon06opacity from "../../assets/icons/uploadIcon06opacity.svg";
import settingsIcon from "../../assets/icons/settingsIcon.svg";
import settingsIcon06opacity from "../../assets/icons/settingsIcon06opacity.svg";
import integrationsIcon from "../../assets/icons/integrationsIcon.svg";
import integrationsIcon06opacity from "../../assets/icons/integrationsIcon06opacity.svg";
import helpIcon from "../../assets/icons/helpIcon.svg";
import helpIcon06opacity from "../../assets/icons/helpIcon06opacity.svg";
import workflowIcon from "../../assets/icons/workflowsIcon.svg";
import workflowIcon06opacity from "../../assets/icons/workflowsIcon06opacity.svg";
import agentIcon06opacity from "../../assets/icons/agentIcon06opacity.svg";
import agentIcon from "../../assets/icons/agentIcon.svg";

import { colors } from "../../themeSettings";
import logoutIcon from "../../assets/icons/logoutIcon.svg";

export const AppNavLink = styled(RouterLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  &:hover {
    &:not(.active) > .MuiListItemIcon-root {
      background-color: ${colors.hl20};
    }
  }
`;

export const ExternalLink = styled(`a`)`
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    &:not(.active) > .MuiListItemIcon-root {
      background-color: ${colors.hl20};
    }
  }
`;

export const Logout = styled(`li`)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  &:hover {
    &:not(.active) > .MuiListItemIcon-root {
      background-color: ${colors.hl20};
    }
  }
`;

export const Settings = styled(RouterLink)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 170px;
  cursor: pointer;
  &:hover {
    .MuiListItemIcon-root {
      background-color: ${colors.hl20};
    }
  }
`;

export const ProjectNavLink = styled(RouterLink)`
  color: #000;
  line-height: 22px;
  text-decoration: none;
`;

export const Avatar = styled(MuiAvatar)`
  height: 40px;
  width: 40px;
  background-color: rgba(237, 240, 252, 0.1);
  border-radius: 50%;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: initial;
  border-radius: 6px;
  transition: background-color 100ms ease-out;
  background-color: ${props => (props.$isActive ? "#1D42C9" : "initial")};
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ProjectsListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${projectsIcon})` : `url(${projectsIcon06opacity})`};
`;

export const HelpListIcon = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${helpIcon})` : `url(${helpIcon06opacity})`};
`;
export const MatchingListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${matchingIcon})` : `url(${matchingIcon06opacity})`};
`;
export const LookerExploreItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${lookerExploreIcon})` : `url(${lookerExploreIcon06opacity})`};
`;

export const WorkflowsListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${workflowIcon})` : `url(${workflowIcon06opacity})`};
`;
export const AgentListItem = styled(ListItemIcon)`
  background-image: ${props =>
  props.$isActive ? `url(${agentIcon})` : `url(${agentIcon06opacity})`};
`;
export const LookerDashboardItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${lookerDashboardIcon})` : `url(${lookerDashboardIcon06opacity})`};
`;
export const UploadListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${uploadIcon})` : `url(${uploadIcon06opacity})`};
`;
export const SettlementsListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${settlementIcon})` : `url(${settlementIcon06opacity})`};
`;
export const SettingsListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${settingsIcon})` : `url(${settingsIcon06opacity})`};
`;
export const LogoutListItem = styled(ListItemIcon)`
  background-image: url(${logoutIcon});
`;

export const IntegrationsListItem = styled(ListItemIcon)`
  background-image: ${props =>
    props.$isActive ? `url(${integrationsIcon})` : `url(${integrationsIcon06opacity})`};
`;

export const ListItemIconSecondary = styled(ListItemIcon)`
  background-color: ${props => (props.$isActive ? "#1D42C9" : "initial")};
  color: ${props =>
    props.$isSecondary ? (props.$isActive ? "#fff" : "rgba(255, 255, 255, 0.6)") : "#fff"};
`;

export const LastListItemIcon = styled(ListItemIcon)``;

export const ListContainer = styled(Box)`
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListItem = styled(Box)`
  display: ${props => (props.$isUnassigned ? "none" : "block")};
  border-radius: 6px;
  padding: 8px 10px;
  transition: background-color 100ms ease-out;
  background-color: ${props => (props.$isActive ? "#edf0fc" : "initial")};
  &:hover {
    background-color: ${props => (props.$isActive ? "#edf0fc" : "rgba(0, 0, 0, 0.04);")};
  }
`;
