/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentConfigDto } from '../models/AgentConfigDto';
import type { AgentStepDto } from '../models/AgentStepDto';
import type { DownloadDto } from '../models/DownloadDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyWebAgentSessionsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public startSession(
    requestBody?: AgentConfigDto,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/session',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns number Success
   * @throws ApiError
   */
  public getAllSessions(): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/session',
    });
  }

  /**
   * @param sessionId
   * @returns any Success
   * @throws ApiError
   */
  public finishSession(
    sessionId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/web-agent/session/{sessionId}',
      path: {
        'sessionId': sessionId,
      },
    });
  }

  /**
   * @param sessionId
   * @param accept
   * @returns AgentStepDto Success
   * @throws ApiError
   */
  public stepSession(
    sessionId: string,
    accept?: boolean,
  ): CancelablePromise<AgentStepDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/session/{sessionId}/step',
      path: {
        'sessionId': sessionId,
      },
      query: {
        'accept': accept,
      },
    });
  }

  /**
   * @param sessionId
   * @returns DownloadDto Success
   * @throws ApiError
   */
  public downloadSession(
    sessionId: string,
  ): CancelablePromise<DownloadDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/session/{sessionId}/download',
      path: {
        'sessionId': sessionId,
      },
    });
  }

  /**
   * @param sessionId
   * @param requestBody
   * @returns string Success
   * @throws ApiError
   */
  public setPrompt(
    sessionId: string,
    requestBody?: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/web-agent/session/{sessionId}/prompt',
      path: {
        'sessionId': sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
