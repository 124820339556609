import { Box, Typography } from "@mui/material";
import React from "react";
import AgentRunsTable from "./AgentRunsTable";
import { AgentRunWithAgent } from "../common/types";
import { WebAgentDto } from "../../../spec/bo";

interface AgentRunsProps {
  agents: WebAgentDto[];
}

export function AgentRuns({ agents }: AgentRunsProps) {
  const historicalRuns: AgentRunWithAgent[] = agents.flatMap(agent =>
    agent.runs.map<AgentRunWithAgent>(run => ({ agent: agent, run: run }))
  );

  return (
    <Box sx={{ display: "flex", width: "90%" }}>
      <Box sx={{ py: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          {/* <Typography variant={"subtitle1"} sx={{mt: "24px", mb: "20px"}}>Next in schedule</Typography>
        <AgentRunsTable agentsRuns={[]} variant="scheduled"/> */}

          <Typography variant={"subtitle1"} sx={{ mt: "24px", mb: "20px" }}>
            Completed runs
          </Typography>
          <AgentRunsTable agentsRuns={historicalRuns} variant="completed" />
        </Box>
      </Box>
    </Box>
  );
}
