import { Box } from "@mui/material";
import { ReactNode } from "react";

import HeaderProject from "../components/Headers/HeaderProject";
import ProjectSidebar from "../components/Sidebar/ProjectSidebar";

const ProjectsContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
      <HeaderProject
        title=""
        moreOptions={{
          handleDelete: null,
          handleEdit: null,
        }}
      />

      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <ProjectSidebar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectsContainer;
