import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { MouseEventHandler, useState } from "react";

const MAX_LENGTH = 100;

interface ICreateAgentDialog {
  success: (name: string) => Promise<void>;
  cancel: MouseEventHandler;
}

const CreateAgentDialog = ({ success, cancel }: ICreateAgentDialog) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_LENGTH) {
      setInputValue(value);
    }
  };

  const handleSave = async () => {
    if (!inputValue.trim()) return;
    setLoading(true);
    await success(inputValue.trim());
    setLoading(false);
  };

  return (
    <Dialog open={true} onClose={cancel}>
      <Box sx={{ position: "absolute", right: "0px", top: "0px", zIndex: "2" }}>
        <IconButton onClick={cancel} sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.25)" }}>
          <CancelIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ width: "calc(312px - 32px)", p: 2 }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Create new agent</DialogTitle>
        <DialogContent sx={{ p: 0, mb: 5 }}>
          <Box sx={{ width: "280px", display: "flex", flexDirection: "column", mt: 2 }}>
            <TextField
              label={""}
              placeholder={"Enter agent name"}
              value={inputValue}
              autoFocus
              onChange={handleNameChange}
              inputProps={{
                maxLength: MAX_LENGTH,
              }}
              size="small"
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-end", p: 0 }}>
          <LoadingButton
            variant="contained"
            data-action="Create agent [CreateAgentDialog]"
            disabled={!inputValue.trim()}
            loading={loading}
            onClick={handleSave}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateAgentDialog;
