import { Box, Button, Tab, Tabs } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import AppSidebar from "../../../components/Sidebar/AppSidebar";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ReiterateLogoBlue } from "../../../assets/icons/logoLargeBlue.svg";
import { useNavigate } from "react-router-dom";
import { ListAltRounded } from "@mui/icons-material";
import { ReactComponent as AgentIconBlack } from "../../../assets/icons/agentIconBlack.svg";
import { AgentsListView } from "./AgentsListView";
import { AgentRuns } from "./AgentRuns";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";
import CreateAgentDialog from "./CreateAgentDialog";

interface AgentsViewProps {}

interface AgentsTopBarProps {
  handleOpenCreateAgent: () => void;
  handleTabChange?: (newValue: number) => void;
}

function AgentsTopBar({ handleOpenCreateAgent, handleTabChange }: AgentsTopBarProps) {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleTabChange?.(newValue);
  };

  return (
    <Box sx={{ display: "flex", borderBottom: "0.5px solid #dcdcdc", width: "100%" }}>
      <Box sx={{ py: 2, display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
          <ReiterateLogoBlue width="116px" style={{ margin: "0 20px 0 24px" }} />
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              minHeight: "unset",
              "& .MuiTabs-flexContainer": {
                display: "flex",
                alignItems: "center !important",
                maxHeight: "32px",
              },
              ".MuiTab-iconWrapper": {
                marginBottom: "0 !important",
                marginRight: "4px",
              },
              ".MuiTab-root": {
                borderRadius: "24px !important",
              },
              ".Mui-selected": {
                color: `#142E90 !important`,
                backgroundColor: "#EEF1FB",
                minHeight: "unset",
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
              },
            }}
          >
            <Tab
              label="Agent setup"
              sx={{
                color: "black",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                height: "32px",
              }}
              icon={value === 0 ? <AgentIconBlack /> : <AgentIconBlack />}
            />
            <Tab
              label="Run history"
              sx={{
                color: "black",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                height: "32px",
              }}
              icon={<ListAltRounded fontSize="small" />}
            />
          </Tabs>
          <Box ml={"auto"} mr={2}>
            <Button
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenCreateAgent}
            >
              Create agent
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const AgentsView = ({}: AgentsViewProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [agents, setAgents] = useState<WebAgentDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const boApiClient = useBoApiClient();

  const handleOpenCreateAgent = () => {
    setDialogOpen(true);
  };

  const handleCreateAgent = async (name: string) => {
    const agent = await boApiClient.proxyWebAgentAgents.createAgent(name, {
      url: "",
      prompt: "",
      parameters: {},
    });
    navigate(`/app/agents/${agent.id}/settings`);
    setDialogOpen(false);
  };

  const handleCancelCreate = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    try {
      setLoading(true);
      boApiClient.proxyWebAgentAgents.getAllAgents().then(agents => setAgents(agents));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "row",
        flex: 1,
        overflow: "hidden",
      }}
    >
      <AppSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <AgentsTopBar
          handleOpenCreateAgent={handleOpenCreateAgent}
          handleTabChange={handleTabChange}
        />
        <Box
          id="agents-container"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {tabIndex === 0 && <AgentsListView webAgents={agents} onClick={() => {}} />}
          {tabIndex === 1 && <AgentRuns agents={agents} />}
        </Box>
      </Box>

      {dialogOpen && <CreateAgentDialog success={handleCreateAgent} cancel={handleCancelCreate} />}
    </Box>
  );
};

export default AgentsView;
