import SendIcon from "@mui/icons-material/Send";
import { Button, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Hotkeys from "react-hot-keys";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { ConnectionStatus, useAgentSession } from "../../../hooks/useAgentSession";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { AgentStep } from "../../../spec/agent";
import { AgentConfigDto, WebAgentDto } from "../../../spec/bo";
import AgentSetupStepperWindow from "../common/AgentSetupStepperWindow";
import { Message } from "../common/agentTypes";
import SidebarWindow from "../common/SidebarWindow";
import { PromptEditorInterfaceState } from "../common/types";
import { ConnectionIndicator } from "./ConnectionIndicator";
import { MessageBox, MessageBoxes } from "./MessageBoxes";

interface PromptEditorProps {}

// User enters prompt
// Agent is thinking - returns thought & proposes action
// User accepts or rejects action

export type PromptLine = string;

function AgentTextInput(
  handleKeyPress: (keyName, event, handle) => void,
  handleSendMessage: () => void,
  currentMessage: string,
  setCurrentMessage: (value: ((prevState: string) => string) | string) => void
): React.JSX.Element {
  return (
    <OutlinedInput
      style={{ width: "100%" }}
      minRows={1}
      maxRows={4}
      onKeyDown={e => {
        handleKeyPress(null, e, null);
      }}
      placeholder={"Type your prompt..."}
      endAdornment={
        <InputAdornment position="end">
          <IconButton edge="end" type="submit" onClick={handleSendMessage}>
            <SendIcon />
          </IconButton>
        </InputAdornment>
      }
      autoFocus
      multiline
      value={currentMessage}
      onChange={e => setCurrentMessage(e.target.value)}
    />
  );
}

function ActionButtons(handleAcceptAgentAction: () => void, handleRejectAgentAction: () => void) {
  return (
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <Button onClick={handleAcceptAgentAction} variant="contained" fullWidth sx={{ mr: 2 }}>
        Confirm action
      </Button>
      <Button
        onClick={handleRejectAgentAction}
        variant="outlined"
        fullWidth
        sx={{
          backgroundColor: "white",
          cursor: "pointer !important",
          ml: 2,
        }}
      >
        Decline
      </Button>
    </Box>
  );
}

const PromptEditorView = ({}: PromptEditorProps): React.JSX.Element => {
  const [messages, setMessages] = useState<Message[]>([{ type: "tutorial" }]);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(messages.length - 1);
  const [selectedStep, setSelectedStep] = useState<Message | null>(null);
  const [interfaceState, setInterfaceState] =
    useState<PromptEditorInterfaceState>("WaitingForConnection");
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const { agentUuid } = useParams();
  const {
    connectionStatus: connectionStatusSession,
    sessionUuid: sessionUuidSession,
    startSession,
    deleteSession,
  } = useAgentSession(agentUuid, handleConnectStateChange);
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>(connectionStatusSession);
  const [sessionUuid, setSessionUuid] = useState<string | null>(sessionUuidSession);
  const [firstInteraction, setFirstInteraction] = useState<boolean>(true);
  const [agent, setAgent] = useState<WebAgentDto | null>(null);

  const [prompt, setPrompt] = useState<PromptLine[]>([]);

  const lastMessageRef = useRef(null);

  function scrollToElement() {
    lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToElement();
  }, [messages, interfaceState]);

  // Agents API
  const boApiClient = useBoApiClient();

  let messagesBuffer = [];
  const navigate = useNavigate();
  const location = useLocation();

  function handleConnectStateChange(uuid: string, status: ConnectionStatus) {
    setConnectionStatus(status);
    setSessionUuid(uuid);
    if (status === "connected") {
      if (interfaceState === "WaitingForConnection") {
        setInterfaceState("WaitingForPrompt");
      }
    } else if (status === "disconnected") {
      setInterfaceState("WaitingForConnection");
    }
  }

  useEffect(() => {
    if (connectionStatus === "connected" && firstInteraction) {
      requestAgentThought(sessionUuid);
    }
  }, [connectionStatus]);

  function handlePromptingCompleted() {
    navigate(`/app/agents/${agentUuid}/schedule`);
  }

  function handleClickMessage(index: number) {
    const setIndex = selectedIndex === index ? null : index;
    setSelectedIndex(setIndex);
    if (setIndex !== null && messages[index].type === "agent") {
      setSelectedStep(messages[index]);
    } else {
      setSelectedStep(null);
    }
  }

  function handleAddAgentMessage(step: AgentStep) {
    const m: Message = { type: "agent", message: step };
    messagesBuffer.push(m);
    if (step.action === null) {
      setInterfaceState("WaitingForPrompt");
    } else {
      setInterfaceState("WaitingForConfirmation");
    }
    const newHistory = [...messages, ...messagesBuffer];
    setMessages(newHistory);
    setSelectedIndex(newHistory.length - 1);
    setSelectedStep(newHistory[selectedIndex]);
  }

  // function to fetch agent response as if it came externally
  async function requestAgentThought(sessionUuid: string, accept = true) {
    setInterfaceState("WaitingForAgent");

    // ask agent for next step
    console.log(sessionUuid);
    boApiClient.proxyWebAgentSessions.stepSession(sessionUuid, accept).then((step: AgentStep) => {
      if (firstInteraction) {
        // if the agent came back with a message that still requires approval,
        // save the last message to the stack and request approval for next action
        setSelectedStep({ type: "agent", message: step });
        setFirstInteraction(false);
      }

      handleAddAgentMessage(step);
    });
  }

  function createPrompt(newPrompt: PromptLine[]) {
    return newPrompt.map((line, i) => i + 1 + ": " + line).join("\n");
  }

  function handleSendMessage() {
    if (connectionStatus !== "connected") {
      return;
    }

    if (currentMessage === "") {
      return;
    }

    const m: Message = { type: "user", message: currentMessage };
    messagesBuffer.push(m);

    setPrompt([...prompt, currentMessage]);
    setMessages([...messages.filter((m: Message) => m.type !== "done"), ...messagesBuffer]);
    setCurrentMessage("");

    const request = createPrompt([...prompt, currentMessage]);
    boApiClient.proxyWebAgentSessions.setPrompt(sessionUuid, request).then(() => {
      requestAgentThought(sessionUuid, false);
    });
  }

  // Once we accept the agent action
  function handleAcceptAgentAction() {
    requestAgentThought(sessionUuid);
  }

  function handleRejectAgentAction() {
    if (messages.at(-2)?.type === "user") {
      setMessages(messages.slice(0, messages.length - 2));
      setInterfaceState("WaitingForPrompt");
    } else {
      setMessages(messages.slice(0, messages.length - 1));
      setInterfaceState("WaitingForPrompt");
    }
  }

  function teardownComponent(sessionUuid: string) {
    if (sessionUuid) {
      deleteSession();
    }
    return;
  }

  useEffect(() => {
    startSession();
    console.log(agentUuid);
    boApiClient.proxyWebAgentAgents.getAgent(agentUuid).then(a => {
      setAgent(a);
    });
  }, []);

  useEffect(() => {
    setSelectedStep(messages[selectedIndex]);
  }, [selectedIndex]);

  useEffect(() => {
    // This effect runs whenever the location (route) changes
    teardownComponent(sessionUuid);

    // Do something when the route changes, like logging analytics or resetting state
  }, [location]);

  const handleKeyPress = (keyName, event, handle) => {
    if (interfaceState === "WaitingForPrompt") {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleSendMessage();
      }
    } else if (interfaceState === "WaitingForConfirmation") {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleAcceptAgentAction();
      }
    }
  };

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [enable, setEnable] = useState(false);
  const handleSave = async () => {
    const agentConfig: AgentConfigDto = {
      url: agent?.config?.url,
      prompt: createPrompt(prompt),
      options: agent?.config?.options,
      parameters: agent?.config?.parameters,
    };
    await boApiClient.proxyWebAgentAgents.updateAgent(agentUuid, agentConfig);
    navigate(`/app/agents/${agentUuid}/schedule`);
  };

  function debugComponent() {
    return;
  }

  function SelectedImage() {
    return (
      selectedStep?.type === "agent" &&
      selectedStep?.message?.screenshot && (
        <Box sx={{ mx: 16, padding: 1 }}>
          <img
            style={{ borderRadius: 10 }}
            width={"100%"}
            src={`data:image/png;base64,${selectedStep?.message?.screenshot}`}
            alt="screenshot"
          />
        </Box>
      )
    );
  }

  // @ts-ignore
  return (
    <SidebarWindow>
      <Hotkeys
        keyName="ctrl+enter,command+enter"
        onKeyDown={handleKeyPress}
        onKeyUp={handleKeyPress}
      />
      <AgentSetupStepperWindow
        stepperOptions={{ agentUuid: agentUuid, activeStep: 1, handleNextButtonSave: handleSave }}
      >
        <Box sx={{ width: "calc(100vw - 64px)" }}>
          {/* Flexes left and right side*/}
          <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100vh - 69px)" }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flex: 1,
                overflow: "hidden",
              }}
            >
              {/* Left side */}
              <Box sx={{ width: "35%", display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                  {/* Agent messages */}
                  <Box
                    sx={{
                      height: "calc(100vh - 56px)",
                      flexDirection: "column",
                      overflowY: "scroll",
                    }}
                  >
                    <MessageBoxes
                      messages={messages}
                      selectedIndex={selectedIndex}
                      interfaceState={interfaceState}
                      waitingAgentResponse={interfaceState === "WaitingForAgent"}
                      handleClickMessage={handleClickMessage}
                      handlePromptingCompleted={handlePromptingCompleted}
                    />
                    {interfaceState === "WaitingForAgent" && (
                      <MessageBox loading selected={true} side={"left"} />
                    )}
                    <Box ref={lastMessageRef} />
                  </Box>
                  <Box style={{ alignItems: "center", minHeight: 100 }}>
                    {interfaceState === "WaitingForConfirmation" &&
                      ActionButtons(handleAcceptAgentAction, handleRejectAgentAction)}
                    {interfaceState === "WaitingForPrompt" &&
                      AgentTextInput(
                        handleKeyPress,
                        handleSendMessage,
                        currentMessage,
                        setCurrentMessage
                      )}
                  </Box>
                </Box>
              </Box>

              {/* Right side - with image */}
              <Box
                sx={{
                  width: "65%",
                  backgroundColor: "#eee",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ConnectionIndicator state={connectionStatus} />
                {connectionStatus === "loading" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "80%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography color={"#6E6E6E"}>Connecting to browser...</Typography>
                      <BounceLoader color={"#6E6E6E"} size={100}></BounceLoader>
                    </Box>
                  </Box>
                )}
                {/*<Box><Typography> interfaceState: {interfaceState} </Typography>
                  <Typography> connectionStatus: {connectionStatus} </Typography>
                  <Typography> sessionUuid: {sessionUuid} </Typography>
                  <Typography> messages: {JSON.stringify(messages)} </Typography>
                  <Typography> prompt: </Typography> </Box>*/}
                {<SelectedImage />}

                <Button onClick={startSession}></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </AgentSetupStepperWindow>
    </SidebarWindow>
  );
};

export default PromptEditorView;
