/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionMatchingRun } from '../models/TransactionMatchingRun';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionMatchingRunsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Gets the last reconciliation run
   * @returns TransactionMatchingRun Success
   * @throws ApiError
   */
  public getLatestTransactionMatchingRun(): CancelablePromise<TransactionMatchingRun> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v2/reconciliation-runs/last',
    });
  }

}
