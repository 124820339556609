const config = {
  API_URL: import.meta.env.VITE_API_URL as string,
  API_URL_SHORT: import.meta.env.VITE_API_URL_SHORT as string,
  API_URL_BACKOFFICE: import.meta.env.VITE_API_URL_BACKOFFICE as string,
  API_URL_AGENT: import.meta.env.VITE_API_URL_AGENT as string,

  API_VERSION: import.meta.env.VITE_API_VERSION as string,
  API_VERSION_BACKOFFICE: import.meta.env.VITE_API_VERSION_BACKOFFICE as string,
  API_VERSION_AGENT: import.meta.env.VITE_API_VERSION_AGENT as string,

  API_WS_URL: import.meta.env.VITE_API_WS_URL as string,
  API_SIGNALR_URL: import.meta.env.VITE_API_SIGNALR_URL as string,
};

export default config;
