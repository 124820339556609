import { Persistence } from "mixpanel-browser";

export const prodMixpanelConfig = {
  token: "2d310928932483f3008659d002275575",
  attributes: {
    debug: false,
    track_pageview: true,
    persistence: "localStorage" as Persistence,
    ignore_dnt: true,
  },
};

export const devMixpanelConfig = {
  token: "2bfa972a4dc2055d3ffb7d4d9489eae5",
  attributes: {
    debug: true,
    track_pageview: false,
    persistence: "localStorage" as Persistence,
    ignore_dnt: false,
    opt_out_tracking_by_default: true,
    opt_out_persistence_by_default: true,
    disable_persistence: true,
    disable_cookie: true,
    /**
     * Attributes below are used for testing purposes. Remove mixpanel.disable(); from App.tsx as well.
     */
    // debug: true,
    // track_pageview: true,
    // persistence: "localStorage" as Persistence,
    // ignore_dnt: true,
  },
};
