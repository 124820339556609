import { Box } from "@mui/system";
import { Button, Divider, Typography } from "@mui/material";
import { BounceLoader } from "react-spinners";
import React from "react";
import { AgentMessage } from "../common/agentTypes";

interface AgentDoneMessageBoxProps {
  selected?: boolean;
  loading?: boolean;
  done?: boolean;
  clickHandler?: () => void;
  finishRunHandler?: () => void;
}

export const AgentDoneMessageBox = ({
  clickHandler,
  finishRunHandler,
  selected = false,
  loading = false,
}: AgentDoneMessageBoxProps) => {
  const unselectedColor = "#C7E3F0";
  const selectedColor = "#A1B8C2";
  const color = selected ? selectedColor : unselectedColor;
  const space = (
    <Box width="20%" height="100%">
      {" "}
    </Box>
  );
  const message_box = (
    <Box style={{ padding: 18 }} onClick={clickHandler}>
      <Divider>
        <Typography color="#666">Your last prompt is completed</Typography>
      </Divider>
      <Typography color="#666" align={"center"}>
        Great! What next? Type the next prompt or click here to review the complete instructions.
      </Typography>
    </Box>
  );

  const loading_box = (
    <Box
      style={{
        backgroundColor: color,
        borderRadius: 10,
        padding: 18,
        justifyContent: "center",
        display: "flex",
      }}
    >
      <BounceLoader size={25} />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mt: 4,
        marginInline: 12,
        marginBlock: 12,
        width: "100%",
      }}
    >
      <Box width="100%">{message_box}</Box>
    </Box>
  );
};
