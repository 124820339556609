/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkflowRunSaveRequestDto } from '../models/WorkflowRunSaveRequestDto';
import type { WorkflowSavedRunDto } from '../models/WorkflowSavedRunDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowSavedRunsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param workflowId
   * @param skip
   * @param limit
   * @returns WorkflowSavedRunDto Success
   * @throws ApiError
   */
  public getAll(
    workflowId?: string,
    skip?: number,
    limit: number = 100,
  ): CancelablePromise<Array<WorkflowSavedRunDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/savedRuns',
      query: {
        'workflowId': workflowId,
        'skip': skip,
        'limit': limit,
      },
    });
  }

  /**
   * @param id
   * @param includeDefition
   * @returns WorkflowSavedRunDto Success
   * @throws ApiError
   */
  public get(
    id: string,
    includeDefition: boolean = false,
  ): CancelablePromise<WorkflowSavedRunDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/savedRuns/{id}',
      path: {
        'id': id,
      },
      query: {
        'includeDefition': includeDefition,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns WorkflowSavedRunDto Success
   * @throws ApiError
   */
  public update(
    id: string,
    requestBody?: WorkflowRunSaveRequestDto,
  ): CancelablePromise<WorkflowSavedRunDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/workflows/savedRuns/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public delete(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/workflows/savedRuns/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param workflowId
   * @param requestBody
   * @returns WorkflowSavedRunDto Success
   * @throws ApiError
   */
  public create(
    workflowId: string,
    requestBody?: WorkflowRunSaveRequestDto,
  ): CancelablePromise<WorkflowSavedRunDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows/{workflowId}/savedRuns',
      path: {
        'workflowId': workflowId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
