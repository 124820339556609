import { useAuth0 } from "@auth0/auth0-react";
import debounce from "lodash/debounce";
import { useState, useEffect, useCallback } from "react";

import { agentApiClient } from "../core/agentApi";
import { AUTH0_ORG_CLAIM_URL } from "../utils/auth/config";

import { useAgentApiClient } from "./useAgentApiClient";
import { useBoApiClient } from "./useBoApiClient";

export type ConnectionStatus = "connected" | "loading" | "disconnected";

export function useAgentSession(
  agentUuid: string,
  onConnectionChange: (sessionUuid: string, status: ConnectionStatus) => void
) {
  const [sessionUuid, setSessionUuid] = useState<string | null>(null); // Session data
  const [connectionStatus, setConnectionStatus_] = useState<ConnectionStatus>("disconnected");

  const boApiClient = useBoApiClient();

  function handleConnect(newSessionUuid) {
    setSessionUuid(newSessionUuid);
    setConnectionStatus("connected");
    onConnectionChange(newSessionUuid, "connected");
  }

  function handleDisconnect() {
    setSessionUuid(null);
    setConnectionStatus("disconnected");
    onConnectionChange(sessionUuid, "disconnected");
  }

  function setConnectionStatus(status: ConnectionStatus) {
    onConnectionChange(sessionUuid, status);
    setConnectionStatus_(status);
  }

  const _startSession = useCallback(async () => {
    if (connectionStatus === "loading") return;
    if (connectionStatus === "connected" || sessionUuid) {
      deleteSession();
    }

    setConnectionStatus("loading");
    try {
      const agent = await boApiClient.proxyWebAgentAgents.getAgent(agentUuid);
      const newSessionUuid = await boApiClient.proxyWebAgentSessions.startSession({
        ...agent.config,
        prompt: "Do absolutely nothing, return with DONE action and wait for further instructions.",
      });

      handleConnect(newSessionUuid);
    } catch (e) {
      handleDisconnect();
    }
  }, [sessionUuid]);

  const deleteSession = useCallback(() => {
    setConnectionStatus("disconnected");
    if (sessionUuid) {
      agentApiClient.sessions.finishAgentSessions(sessionUuid);
      handleDisconnect();
    }
  }, []);

  const startSession = debounce(_startSession, 500);

  return { sessionUuid, connectionStatus, startSession, deleteSession };
}
