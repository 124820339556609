import React from "react";
import AppSidebar from "../../../components/Sidebar/AppSidebar";
import { Box } from "@mui/system";


const SidebarWindow = ({children}) => {
  return (<Box
    sx={{
      display: "flex",
      flexDirection: "row",
      flex: 1,
      height: "100vh"
    }}
  >
    <AppSidebar />
    {children}
  </Box>)

}

export default SidebarWindow