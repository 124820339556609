/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentConfigDTO } from '../models/AgentConfigDTO';
import type { AgentRun } from '../models/AgentRun';
import type { AgentTimer } from '../models/AgentTimer';
import type { WebAgent } from '../models/WebAgent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AgentsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * AddTimer
   * @param agentId
   * @param organization
   * @param requestBody
   * @returns AgentTimer Document created, URL follows
   * @throws ApiError
   */
  public addTimer(
    agentId: string,
    organization: string,
    requestBody: string,
  ): CancelablePromise<AgentTimer> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/agent/{agent_id}/timer',
      path: {
        'agent_id': agentId,
      },
      headers: {
        'organization': organization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * List
   * @param organization
   * @returns WebAgent Request fulfilled, document follows
   * @throws ApiError
   */
  public listAgents(
    organization: string,
  ): CancelablePromise<Array<WebAgent>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/agent',
      headers: {
        'organization': organization,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * Create
   * @param name
   * @param organization
   * @param requestBody
   * @returns WebAgent Document created, URL follows
   * @throws ApiError
   */
  public createAgents(
    name: string,
    organization: string,
    requestBody: AgentConfigDTO,
  ): CancelablePromise<WebAgent> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/agent',
      headers: {
        'organization': organization,
      },
      query: {
        'name': name,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * DeleteTimer
   * @param timerId
   * @param organization
   * @param agentId
   * @returns void
   * @throws ApiError
   */
  public deleteTimer(
    timerId: string,
    organization: string,
    agentId: string,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/v1/agent/{agent_id}/timer/{timer_id}',
      path: {
        'timer_id': timerId,
        'agent_id': agentId,
      },
      headers: {
        'organization': organization,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * Get
   * @param agentId
   * @param organization
   * @returns WebAgent Request fulfilled, document follows
   * @throws ApiError
   */
  public getAgent(
    agentId: string,
    organization: string,
  ): CancelablePromise<WebAgent> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/v1/agent/{agent_id}',
      path: {
        'agent_id': agentId,
      },
      headers: {
        'organization': organization,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * Update
   * @param agentId
   * @param organization
   * @param requestBody
   * @returns WebAgent Request fulfilled, document follows
   * @throws ApiError
   */
  public updateAgent(
    agentId: string,
    organization: string,
    requestBody: AgentConfigDTO,
  ): CancelablePromise<WebAgent> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/v1/agent/{agent_id}',
      path: {
        'agent_id': agentId,
      },
      headers: {
        'organization': organization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

  /**
   * StartRun
   * @param agentId
   * @param organization
   * @returns AgentRun Document created, URL follows
   * @throws ApiError
   */
  public startAgentRun(
    agentId: string,
    organization: string,
  ): CancelablePromise<AgentRun> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/v1/agent/{agent_id}/run',
      path: {
        'agent_id': agentId,
      },
      headers: {
        'organization': organization,
      },
      errors: {
        400: `Bad request syntax or unsupported method`,
      },
    });
  }

}
