import React, { useEffect, useState } from "react";
import {
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../../components/Styled/StyledTableCell";
import StyledTableRow from "../../../components/Styled/StyledTableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Menu from "@mui/material/Menu";
import { Order, OrderBy } from "../common/types";
import { tableHeadersAgentConfig } from "../common/utils";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";
import AgentRunAlert from "./AgentRunAlert";

interface AgentsTableProps {
  agents: WebAgentDto[];
}

const AgentsTable = ({ agents }: AgentsTableProps): React.JSX.Element => {
  const [orderBy, setOrderBy] = useState<OrderBy>("created_date");
  const [order, setOrder] = useState<Order>("asc");
  const [agentsView, setAgentsView] = useState<WebAgentDto[]>(agents);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAgent, setselectedAgent] = useState<WebAgentDto | null>(null);
  const [showRunAlert, setShowRunAlert] = useState(false);
  const [runningAgent, setRunningAgent] = useState<WebAgentDto | null>(null);

  const boApiClient = useBoApiClient();

  const navigate = useNavigate();

  const navigateToAgent = (agent: WebAgentDto) => {
    navigate(`/app/agents/${agent.id}/settings`);
  };

  const handleDelete = () => {
    if (selectedAgent) {
      console.log("Deleting agent:", selectedAgent.name);
    }
    handleCloseMenu();
  };

  useEffect(() => {
    const sortedData = agents.sort((a, b) => {
      return a > b ? -1 : 1;
    });
    setAgentsView(sortedData);
  }, [orderBy, order]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setselectedAgent(null);
  };

  const handleSort = (property: OrderBy) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function runAgent(agent: WebAgentDto) {
    setRunningAgent(agent);
    setShowRunAlert(true);
    boApiClient.proxyWebAgentAgents.startRun(agent.id);

    setTimeout(() => {
      setShowRunAlert(false);
      setRunningAgent(null);
    }, 5000);
  }

  return (
    <>
      <TableContainer>
        <Table
          aria-label="saved runs table"
          sx={{ tableLayout: "fixed", width: "100%", borderBottom: "1px solid lightgrey" }}
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
              {tableHeadersAgentConfig.map((header, index) => (
                <StyledTableCell
                  key={index}
                  hasDivider={header.hasDivider}
                  style={{
                    width: header.width,
                    minWidth: header.minWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: 400,
                  }}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : "desc"}
                      onClick={handleSort(header.id)}
                      IconComponent={ArrowDropUpIcon}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,
                          color: "rgba(0, 0, 0, 0.54) !important",
                        },
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agentsView.length > 0 ? (
              agentsView.map(agent => (
                <StyledTableRow key={agent.id} sx={{ cursor: "pointer" }}>
                  <StyledTableCell style={{ width: tableHeadersAgentConfig[0].width }}>
                    <Typography fontSize={12} noWrap>
                      {agent.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: tableHeadersAgentConfig[1].width }}>
                    {agent.config.url}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "5%" }}>
                    <Tooltip title={"Run agent"}>
                      <IconButton
                        onClick={() => {
                          runAgent(agent);
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Reconfigure agent"}>
                      <IconButton
                        onClick={() => {
                          navigateToAgent(agent);
                        }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow style={{ height: "120px", borderBottom: "1px solid lightgrey" }}>
                <StyledTableCell colSpan={tableHeadersAgentConfig.length} align="center">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={400}
                    color="rgba(0,0,0,0.6)"
                  >
                    There are no saved runs.
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showRunAlert && runningAgent && (
        <AgentRunAlert
          agentName={runningAgent.name}
          onClose={() => {
            setShowRunAlert(false);
            setRunningAgent(null);
          }}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              mt: 0.5,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleDelete}
          sx={{
            fontSize: "12px",
            color: "error.main",
            py: 1,
            minWidth: "120px",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default AgentsTable;
