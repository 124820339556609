/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleSheetsExportOptionsDto } from '../models/GoogleSheetsExportOptionsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GoogleSheetsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns GoogleSheetsExportOptionsDto Success
   * @throws ApiError
   */
  public getConfigOptions(): CancelablePromise<GoogleSheetsExportOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/integrations/googlesheets',
    });
  }

  /**
   * @returns boolean Success
   * @throws ApiError
   */
  public requestAccess(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/integrations/googlesheets/request-access',
    });
  }

  /**
   * @param sheetId
   * @returns string Success
   * @throws ApiError
   */
  public getTabs(
    sheetId: string,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/integrations/googlesheets/tabs/{sheetId}',
      path: {
        'sheetId': sheetId,
      },
    });
  }

}
