import { ApiClientBO } from "../spec/bo";

import config from "./config";

export const boApiClient = new ApiClientBO({
  BASE: config.API_URL_BACKOFFICE,
  VERSION: config.API_VERSION_BACKOFFICE,
});

export type DefaultApiExceptionResponse = {
  statusCode: number;
  message: string;
};

export class DefaultApiError extends Error {
  constructor(readonly res: DefaultApiExceptionResponse) {
    super(res.message);
    Object.setPrototypeOf(this, DefaultApiError.prototype);
  }
}

export enum ApiExceptionErrorCode {
  DATA_FETCH_ERROR = "data_fetch_error",
}

export type ApiExceptionResponse = {
  statusCode: number;
  message: string;
  error_code: ApiExceptionErrorCode;
  node_uuid?: string;
};

export class ApiError extends Error {
  constructor(readonly res: ApiExceptionResponse) {
    super(res.message);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, NetworkError.prototype);
  }
}
