/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LookerAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param src
   * @returns any Success
   * @throws ApiError
   */
  public getAuth(
    src?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/looker/auth',
      query: {
        'src': src,
      },
    });
  }

}
