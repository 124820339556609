import { Box } from "@mui/material";
import { ReactNode } from "react";

import AppSidebar from "../components/Sidebar/AppSidebar";

const AppContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppSidebar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AppContainer;
