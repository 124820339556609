import mixpanel from "mixpanel-browser";
import { createContext } from "react";

interface MixpanelContext {
  mixpanel: typeof mixpanel;
}

const MixpanelContext = createContext<MixpanelContext | null>(null);

export default MixpanelContext;
