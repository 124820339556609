/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkflowDto } from '../models/WorkflowDto';
import type { WorkflowStatusDto } from '../models/WorkflowStatusDto';
import type { WorkflowUpdateDto } from '../models/WorkflowUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public getAll(): CancelablePromise<Array<WorkflowDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows',
    });
  }

  /**
   * @param requestBody
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public create(
    requestBody?: WorkflowUpdateDto,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public get(
    id: string,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns WorkflowDto Success
   * @throws ApiError
   */
  public update(
    id: string,
    requestBody?: WorkflowUpdateDto,
  ): CancelablePromise<WorkflowDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/workflows/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param id
   * @returns any Success
   * @throws ApiError
   */
  public delete(
    id: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/workflows/{id}',
      path: {
        'id': id,
      },
    });
  }

  /**
   * @param id
   * @returns WorkflowStatusDto Success
   * @throws ApiError
   */
  public getStatus(
    id: string,
  ): CancelablePromise<WorkflowStatusDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/{id}/status',
      path: {
        'id': id,
      },
    });
  }

}
