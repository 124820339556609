/* eslint-disable no-console */
import { useAuth0 } from "@auth0/auth0-react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { accessTokenRequest } from "../core/auth";
import config from "../core/config";

import { SignalRContext } from "./SignalRContext";


interface SignalRProviderProps {
  children: ReactNode;
}
// Provider component that manages the SignalR connection
export const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connected, setConnected] = useState<boolean>(false);

  const logSignalR = (message: string, error: unknown = null) => {
    if (import.meta.env.PROD) return;
    console.log(`[SignalR message] ${message}`);
    if (error) {
      console.error(`[SignalR error] ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    const connectToSignalR = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(config.API_SIGNALR_URL, {
          accessTokenFactory: async () => {
            const token = await getAccessTokenSilently(accessTokenRequest);
            return token;
          },
        }) // Add URL and options here
        .withAutomaticReconnect() // Optional: Automatically reconnect if connection is lost
        .build();

      setConnection(newConnection);

      newConnection.onreconnecting(error => {
        logSignalR("Reconnecting...", error);
        setConnected(false);
      });

      newConnection.onreconnected(() => {
        logSignalR("Reconnected.");
        setConnected(true);
      });

      newConnection.onclose(error => {
        logSignalR("Connection closed.", error);
        setConnected(false);
      });

      try {
        logSignalR("Connecting...");
        await newConnection.start();
        logSignalR("Connected.");
        setConnected(true);
      } catch (err: unknown) {
        logSignalR("Error connecting to SignalR.", err);
      }
    };

    if (isAuthenticated) {
      void connectToSignalR();
    } else if (!isAuthenticated && connection) {
      void connection.stop();
      setConnected(false);
      setConnection(null);
    }

    // Clean up the connection on component unmount
    return () => {
      if (connection) {
        void connection.stop();
      }
    };
    // Adding connection to the dependency array will cause the connection to be recreated on every render which infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently]);

  const contextValue = useMemo(
    () => ({
      connection,
      connected,
    }),
    [connection, connected]
  );

  return <SignalRContext.Provider value={contextValue}>{children}</SignalRContext.Provider>;
};
