import { AgentsEmptyState } from "./AgentsEmptyState";
import { Box } from "@mui/material";
import AgentsTable from "./AgentsTable";
import React from "react";
import { WebAgentDto } from "../../../spec/bo";

export function AgentsListView(props: { webAgents: WebAgentDto[], onClick: () => void }) {
  return <>
    {props.webAgents.length === 0 ? (
      <AgentsEmptyState onClick={props.onClick} />
    ) : (
      <Box sx={{ width: "80%", overflow: "auto", mt: 2, alignItems: "center" }}>
        <AgentsTable agents={props.webAgents} />
      </Box>
    )}
  </>;
}