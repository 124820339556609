import { lazy } from "react";

export const lazyWithReload: typeof lazy = importer => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch {
      window.location.reload();
    }
  };
  return lazy(retryImport);
};
