import React from "react";
import { Box } from "@mui/system";
import { BounceLoader } from "react-spinners";
import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const ConnectionSuccessIndicator = () => {
  const color = "#1B581B";
  return <Box sx={{display: "flex", flexDirection: "row", justifyContent: "right", placeItems: "center" }}>
    <Box sx={{ mr: 1 }}><CircleIcon sx={{ fontSize: 12, color: color }} /> </Box>
    <Typography color={color}> Browser connected </Typography>
  </Box>;
};
const ConnectionLoadingIndicator = () => {
  const color = "#6E6E6E";
  return <Box sx={{display: "flex", flexDirection: "row", justifyContent: "right", placeItems: "center" }}>
  </Box>;
};
const ConnectionMissingIndicator = () => {
  const color = "#BA211C";
  return <Box sx={{display: "flex", placeItems: "center" }}>
    <Box sx={{ mr: 1 }}><CircleIcon sx={{ fontSize: 12, color: color }} /> </Box>
    <Typography color={color}> Browser disconnected </Typography>
  </Box>;
};
export const ConnectionIndicator = ({ state }) => {
  let comp;
  switch (state) {
    case "connected":
      comp = <ConnectionSuccessIndicator />;
      break;
    case "loading":
      comp = <ConnectionLoadingIndicator />;
      break;
    case "disconnected":
      comp = <ConnectionMissingIndicator />;
      break;
    default:
      comp = <ConnectionMissingIndicator />;
      break;
  }

  return <Box sx={{ margin: 1}}>{comp}</Box>
};