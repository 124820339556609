import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";

interface AgentRunAlertProps {
  agentName: string;
  onClose?: () => void;
}

const AgentRunAlert = ({ agentName, onClose }: AgentRunAlertProps) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: 2,
        pointerEvents: "auto",
        background: "rgba(0, 0, 0, 0.16)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          position: "absolute",
          right: "20px",
          bottom: "20px",
          zIndex: 3,
          background: "rgba(20, 46, 144, 1)",
          overflow: "hidden",
          borderRadius: "4px",
          "&  .MuiAlert-message, & .MuiPaper-root": {
            overflow: "hidden",
            borderRadius: "4px",
            background: "rgba(20, 46, 144, 1)",
            color: "white",
            display: "flex",
            gap: 2,
            alignItems: "center",
          },
        }}
        onClick={e => e.stopPropagation()}
      >
        <Alert
          severity="info"
          icon={false}
          sx={{ display: "flex !important", flexDirection: "column" }}
          onClose={onClose}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                minWidth: "300px",
              }}
            >
              <AlertTitle>Agent Run Triggered</AlertTitle>
              <CircularProgress variant="indeterminate" size={16} sx={{ color: "white" }} />
            </Box>
            <Box sx={{ mt: 1 }}>
              {agentName} has started running. You can continue using the application.
            </Box>
          </Box>
        </Alert>
      </Box>
    </Box>
  );
};

export default AgentRunAlert;
