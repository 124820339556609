import { useFlags } from "launchdarkly-react-client-sdk";
import React, { ReactElement } from "react";

import Maintenance from "../pages/Maintenance";

interface MaintenanceProviderProps {
  children: ReactElement;
}

const MaintenanceProvider: React.FC<MaintenanceProviderProps> = ({ children }) => {
  const { maintenanceModeForAllOrganizations } = useFlags();

  if (maintenanceModeForAllOrganizations) {
    return <Maintenance />;
  }

  return children;
};

export default MaintenanceProvider;
