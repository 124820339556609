import React, { useEffect, useState } from "react";
import {
  Box,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import StyledTableCell from "../../../components/Styled/StyledTableCell";
import StyledTableRow from "../../../components/Styled/StyledTableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DownloadIcon from "@mui/icons-material/Download";
import Menu from "@mui/material/Menu";
import { AgentRunWithAgent, Order, OrderBy } from "../common/types";
import { tableHeadersAgentRunsCompleted, tableHeadersAgentRunsScheduled } from "../common/utils";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";
import StyledChip from "../../../components/Styled/StyledChip";

interface AgentsTableProps {
  agentsRuns: AgentRunWithAgent[];
  variant: "scheduled" | "completed";
}

const AgentsTable = ({ agentsRuns, variant }: AgentsTableProps): React.JSX.Element => {
  const [orderBy, setOrderBy] = useState<OrderBy>("created_date");
  const [order, setOrder] = useState<Order>("asc");
  const [agentsView, setAgentsView] = useState<AgentRunWithAgent[]>(agentsRuns);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAgent, setselectedAgent] = useState<WebAgentDto | null>(null);
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});

  const boApiClient = useBoApiClient();

  const tableHeaders =
    variant === "scheduled" ? tableHeadersAgentRunsScheduled : tableHeadersAgentRunsCompleted;

  const getLoadingKey = (runId: string, fileName: string) => `${runId}-${fileName}`;

  const handleFileClick = async (
    e: React.MouseEvent,
    runId: string,
    fileName: string,
    agentId: string
  ) => {
    e.stopPropagation();
    const loadingKey = getLoadingKey(runId, fileName);

    try {
      setIsLoading(prev => ({ ...prev, [loadingKey]: true }));
      const blobData = await boApiClient.proxyWebAgentAgents.downloadFile(runId, agentId, fileName);

      const blobUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setIsLoading(prev => ({ ...prev, [loadingKey]: false }));
    }
  };

  const handleDelete = () => {
    if (selectedAgent) {
      console.log("Deleting agent:", selectedAgent.name);
    }
    handleCloseMenu();
  };

  useEffect(() => {
    const sortedData = agentsRuns.sort((a, b) => {
      return a > b ? -1 : 1;
    });
    setAgentsView(sortedData);
  }, [orderBy, order]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setselectedAgent(null);
  };

  const handleSort = (property: OrderBy) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer>
        <Table
          aria-label="saved runs table"
          sx={{ tableLayout: "fixed", width: "100%", borderBottom: "1px solid lightgrey" }}
        >
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
              {tableHeaders.map((header, index) => (
                <StyledTableCell
                  key={index}
                  hasDivider={header.hasDivider}
                  sx={{
                    width: header.width,
                    minWidth: header.minWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: 400,
                  }}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : "desc"}
                      onClick={handleSort(header.id)}
                      IconComponent={ArrowDropUpIcon}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,
                          color: "rgba(0, 0, 0, 0.54) !important",
                        },
                      }}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {agentsView.length > 0 ? (
              agentsView.map(agentsRuns => (
                <StyledTableRow
                  key={agentsRuns.run.id}
                  onClick={() => {}}
                  sx={{ cursor: "pointer" }}
                >
                  <StyledTableCell sx={{ width: tableHeaders[0].width }}>
                    <Typography fontSize={12} noWrap>
                      {agentsRuns.agent.name}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: tableHeaders[1].width, justifyContent: "center" }}>
                    {variant === "completed" &&
                      (agentsRuns.run.success ? (
                        <StyledChip label={"Success"} type={"green"} />
                      ) : (
                        <StyledChip label={"Failed"} type={"grey"} />
                      ))}
                    {variant === "scheduled" && <StyledChip label={"Scheduled"} type={"blue"} />}
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: tableHeaders[2].width }}>
                    <>
                      {agentsRuns.run.files.length === 0 && (
                        <Typography sx={{ color: "rgba(0,0,0,0.35)" }}>N/A</Typography>
                      )}
                      {agentsRuns.run.files.map((file, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            mb: index !== agentsRuns.run.files.length - 1 ? 1 : 0,
                          }}
                        >
                          <Link
                            component="button"
                            onClick={e =>
                              handleFileClick(
                                e,
                                agentsRuns.run.id,
                                file.name || "",
                                agentsRuns.agent.id
                              )
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              color: "primary.main",
                              cursor: "pointer",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontSize={12}
                              fontWeight={400}
                              sx={{ mr: 1 }}
                            >
                              {file.name}
                            </Typography>
                            {isLoading[getLoadingKey(agentsRuns.run.id, file.name || "")] ? (
                              <Box
                                sx={{
                                  width: 16,
                                  height: 16,
                                  border: "2px solid rgba(0,0,0,0.1)",
                                  borderTop: "2px solid currentColor",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                  "@keyframes spin": {
                                    "0%": { transform: "rotate(0deg)" },
                                    "100%": { transform: "rotate(360deg)" },
                                  },
                                }}
                              />
                            ) : (
                              <DownloadIcon sx={{ fontSize: 16 }} />
                            )}
                          </Link>
                        </Box>
                      ))}
                    </>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: tableHeaders[3].width }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <AccountCircleIcon
                        sx={{
                          mr: 1,
                          color: tableHeaders[3]?.mutedColors
                            ? "rgba(0,0,0,1.0)"
                            : "rgba(0,0,0,0.6)",
                        }}
                      />
                      <Typography
                        variant="body1"
                        fontSize={12}
                        fontWeight={400}
                        color={tableHeaders[3]?.mutedColors ? "rgba(0,0,0,1.0)" : "rgba(0,0,0,0.6)"}
                      >
                        Automatic
                      </Typography>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell style={{ width: tableHeaders[4].width }}></StyledTableCell>

                  <StyledTableCell style={{ width: tableHeaders[5].width }}>
                    {agentsRuns.agent.config.url}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow style={{ height: "120px", borderBottom: "1px solid lightgrey" }}>
                <StyledTableCell colSpan={tableHeaders.length} align="center">
                  <Typography
                    variant="body1"
                    fontSize={12}
                    fontWeight={400}
                    color="rgba(0,0,0,0.6)"
                  >
                    {variant === "scheduled"
                      ? "There are not scheduled runs."
                      : "There are no completed runs."}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              mt: 0.5,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleDelete}
          sx={{
            fontSize: "12px",
            color: "error.main",
            py: 1,
            minWidth: "120px",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default AgentsTable;
