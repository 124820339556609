/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { BrandsAPI } from './services/BrandsAPI';
import { ConnectionsAPI } from './services/ConnectionsAPI';
import { DashboardAPI } from './services/DashboardAPI';
import { FileExtractionConfigsAPI } from './services/FileExtractionConfigsAPI';
import { FileExtractionRunsAPI } from './services/FileExtractionRunsAPI';
import { FilesAPI } from './services/FilesAPI';
import { GoogleSheetsAPI } from './services/GoogleSheetsAPI';
import { HealthAPI } from './services/HealthAPI';
import { IceCalculationAPI } from './services/IceCalculationAPI';
import { LookerAPI } from './services/LookerAPI';
import { ModelConfigsAPI } from './services/ModelConfigsAPI';
import { ModelsAPI } from './services/ModelsAPI';
import { ProjectsAPI } from './services/ProjectsAPI';
import { ProxyModelConfigurationsAPI } from './services/ProxyModelConfigurationsAPI';
import { ProxyWebAgentAgentsAPI } from './services/ProxyWebAgentAgentsAPI';
import { ProxyWebAgentSessionsAPI } from './services/ProxyWebAgentSessionsAPI';
import { ResolutionsAPI } from './services/ResolutionsAPI';
import { SettingsAPI } from './services/SettingsAPI';
import { SettlementsAPI } from './services/SettlementsAPI';
import { TasksAPI } from './services/TasksAPI';
import { TransactionGroupsAPI } from './services/TransactionGroupsAPI';
import { TransactionMatchingAPI } from './services/TransactionMatchingAPI';
import { TransactionMatchingRunsAPI } from './services/TransactionMatchingRunsAPI';
import { UsersAPI } from './services/UsersAPI';
import { ValidationConfigsAPI } from './services/ValidationConfigsAPI';
import { WorkflowRunsAPI } from './services/WorkflowRunsAPI';
import { WorkflowsAPI } from './services/WorkflowsAPI';
import { WorkflowSavedRunsAPI } from './services/WorkflowSavedRunsAPI';
import { XeroIntegrationsAPI } from './services/XeroIntegrationsAPI';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClientBO {

  public readonly brands: BrandsAPI;
  public readonly connections: ConnectionsAPI;
  public readonly dashboard: DashboardAPI;
  public readonly fileExtractionConfigs: FileExtractionConfigsAPI;
  public readonly fileExtractionRuns: FileExtractionRunsAPI;
  public readonly files: FilesAPI;
  public readonly googleSheets: GoogleSheetsAPI;
  public readonly health: HealthAPI;
  public readonly iceCalculation: IceCalculationAPI;
  public readonly looker: LookerAPI;
  public readonly modelConfigs: ModelConfigsAPI;
  public readonly models: ModelsAPI;
  public readonly projects: ProjectsAPI;
  public readonly proxyModelConfigurations: ProxyModelConfigurationsAPI;
  public readonly proxyWebAgentAgents: ProxyWebAgentAgentsAPI;
  public readonly proxyWebAgentSessions: ProxyWebAgentSessionsAPI;
  public readonly resolutions: ResolutionsAPI;
  public readonly settings: SettingsAPI;
  public readonly settlements: SettlementsAPI;
  public readonly tasks: TasksAPI;
  public readonly transactionGroups: TransactionGroupsAPI;
  public readonly transactionMatching: TransactionMatchingAPI;
  public readonly transactionMatchingRuns: TransactionMatchingRunsAPI;
  public readonly users: UsersAPI;
  public readonly validationConfigs: ValidationConfigsAPI;
  public readonly workflowRuns: WorkflowRunsAPI;
  public readonly workflows: WorkflowsAPI;
  public readonly workflowSavedRuns: WorkflowSavedRunsAPI;
  public readonly xeroIntegrations: XeroIntegrationsAPI;

  public readonly request: BaseHttpRequest;

  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });

    this.brands = new BrandsAPI(this.request);
    this.connections = new ConnectionsAPI(this.request);
    this.dashboard = new DashboardAPI(this.request);
    this.fileExtractionConfigs = new FileExtractionConfigsAPI(this.request);
    this.fileExtractionRuns = new FileExtractionRunsAPI(this.request);
    this.files = new FilesAPI(this.request);
    this.googleSheets = new GoogleSheetsAPI(this.request);
    this.health = new HealthAPI(this.request);
    this.iceCalculation = new IceCalculationAPI(this.request);
    this.looker = new LookerAPI(this.request);
    this.modelConfigs = new ModelConfigsAPI(this.request);
    this.models = new ModelsAPI(this.request);
    this.projects = new ProjectsAPI(this.request);
    this.proxyModelConfigurations = new ProxyModelConfigurationsAPI(this.request);
    this.proxyWebAgentAgents = new ProxyWebAgentAgentsAPI(this.request);
    this.proxyWebAgentSessions = new ProxyWebAgentSessionsAPI(this.request);
    this.resolutions = new ResolutionsAPI(this.request);
    this.settings = new SettingsAPI(this.request);
    this.settlements = new SettlementsAPI(this.request);
    this.tasks = new TasksAPI(this.request);
    this.transactionGroups = new TransactionGroupsAPI(this.request);
    this.transactionMatching = new TransactionMatchingAPI(this.request);
    this.transactionMatchingRuns = new TransactionMatchingRunsAPI(this.request);
    this.users = new UsersAPI(this.request);
    this.validationConfigs = new ValidationConfigsAPI(this.request);
    this.workflowRuns = new WorkflowRunsAPI(this.request);
    this.workflows = new WorkflowsAPI(this.request);
    this.workflowSavedRuns = new WorkflowSavedRunsAPI(this.request);
    this.xeroIntegrations = new XeroIntegrationsAPI(this.request);
  }
}

