import SidebarWindow from "../common/SidebarWindow";
import AgentSetupStepperWindow from "../common/AgentSetupStepperWindow";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Cron, { HEADER } from "react-cron-generator";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { AgentTimerDto, WebAgentDto } from "../../../spec/bo";

export const AgentScheduleView = ({}) => {
  const [cron, setCron] = React.useState("0 0 1 * *"); // Default to first day of every month at midnight
  const [agent, setAgent] = React.useState<WebAgentDto | null>(null);

  const options = {
    headers: [HEADER.MONTHLY], // Only allow monthly scheduling
    defaultValue: "0 0 1 * *", // Default to first day of every month at midnight
    disabled: ["weekly", "daily", "hourly", "custom"], // Explicitly disable other options
  };

  const navigate = useNavigate();
  const { agentUuid } = useParams();

  const boApiClient = useBoApiClient();

  useEffect(() => {
    fetchAndUpdateAgent();
  }, []);

  function fetchAndUpdateAgent() {
    boApiClient.proxyWebAgentAgents.getAgent(agentUuid).then(a => {
      setAgent(a);
      // If agent has existing timer, set it as the current cron
      if (a?.timers?.length > 0) {
        setCron(a.timers[0].schedule);
      }
    });
  }

  function setAgentCron(schedule: string) {
    // Safety - deletes all previous timers
    for (let i = 0; i < agent?.timers.length; i++) {
      const timer: AgentTimerDto = agent?.timers[i];
      // TODO: After backend is updated to reflect that ID cant be null - remove.
      if (timer?.id) {
        boApiClient.proxyWebAgentAgents.deleteTimer(timer.id, agentUuid);
      }
    }

    boApiClient.proxyWebAgentAgents.addTimer(agentUuid, schedule).then(() => {
      fetchAndUpdateAgent();
    });
  }

  const handleSave = () => {
    setAgentCron(cron);
    navigate(`/app/agents/`);
  };

  return (
    <SidebarWindow>
      <AgentSetupStepperWindow
        stepperOptions={{ activeStep: 2, agentUuid: agentUuid, handleNextButtonSave: handleSave }}
      >
        <Box sx={{ width: "100%", height: "100%", justifyContent: "center", display: "flex" }}>
          <Box width={"60%"} sx={{ justifyContent: "center" }}>
            <Box
              className={"cron-builder-reiterate"}
              sx={{ mt: 4, display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <Cron
                onChange={setCron}
                value={cron}
                showResultText={true}
                showResultCron={true}
                options={options}
              />
            </Box>
          </Box>
        </Box>
      </AgentSetupStepperWindow>
    </SidebarWindow>
  );
};
