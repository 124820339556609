import * as Sentry from "@sentry/browser";

interface ErrorBody {
  [key: string]: unknown;
}

export const errorLoggingAPI = (
  name: string,
  error: Error,
  body: ErrorBody | null = null
): void => {
  Sentry.withScope(scope => {
    scope.setLevel("error");
    if (body && Object.entries(body).length !== 0) {
      Sentry.setExtra("body", body);
    }
    Sentry.setExtra("error", error.stack);
    Sentry.captureMessage(name);
  });
};
